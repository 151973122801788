Map page
<template>
  <div class="map-float-filter" :class="{ active: isActive }">
    <transition name="slide-right-filter" mode="out-in">
      <uello-button
        class="float-right-button px-3 py-4"
        color="red"
        icon="filter"
        iconSize="28"
        @click="active = !active"
        :title="$t('general.filterStatusButton')"
        v-if="!active"
      />
      <div v-else class="filter-container py-2 position-relative bg-white">
        <uello-button
          class="close-icon"
          color="red"
          colorless
          icon="close"
          iconSize="24"
          @click="active = !active"
          :title="$t('general.closeButton')"
        />
        <p class="mt-4 text-dark font-weight-bold d-flex">
          <uello-icon icon="chevron-left" size="28" color="red" left />
          <small class="pt-1 text-primary">
            <strong>{{ $t('general.filterStatusLabel') }}</strong>
          </small>
        </p>
        <!-- <div class="input-container position-relative px-2">
          <b-form-input
            size="sm"
            v-model="searchText"
            class="search-input border-primary"
            :placeholder="$t('form.placeholders.searchStatusText')"
          ></b-form-input>
          <icon icon="magnify" color="var(--dark-darken)" class="search-icon-input" />
        </div> -->
        <div class="">
          <div class="px-2">
            <b-form-checkbox
              :title="$t('form.labels.selectAll')"
              :indeterminate="indeterminate"
              @change="changeAll"
              v-model="allSelected"
              name="select-all"
              aria-describedby="status"
              aria-controls="status"
              switch
            >
              <span class="text-dark" :title="$t('form.labels.selectAll')">{{
                $t('form.labels.selectAll')
              }}</span>
            </b-form-checkbox>
            <v-date-picker
              color="red"
              mode="range"
              v-model="created_at"
              :columns="1"
              :max-date="new Date()"
              :popover="{ placement: 'bottom', visibility: 'click' }"
              @input="formatDate"
              @change="formatDate"
            >
              <uello-button
                color="marine"
                fullwidth
                icon="calendar-month-outline"
                iconSize="20"
                size="small"
                :text="$t('pages.map.dateFilterLabel')"
              />
            </v-date-picker>
            <hr class="bg-light" />
          </div>
          <perfect-scrollbar class="scroll-container px-2">
            <b-form-group>
              <b-form-checkbox-group v-model="filter" :options="statusFilter" switches stacked>
              </b-form-checkbox-group>
            </b-form-group>
          </perfect-scrollbar>
          <b-alert show variant="secondary" class="p-1 mx-2 text-center mt-2">
            <uello-icon icon="information" color="gray" class="mr-1" />
            <small>{{ $t('pages.map.paginationInfoMessage') }}</small>
          </b-alert>
          <uello-pagination
            @click="handlePagination"
            :color="null"
            :current="currentPage"
            :position="null"
            :simple="false"
            :total="Math.ceil(rows / perPage)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { format } from 'date-fns';

import * as types from '@/store/types';
import { UelloButton, UelloIcon, UelloPagination } from '@uello/componentello';

export default {
  name: 'map-float-filter',
  props: {
    filters: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
  },
  components: {
    UelloButton,
    UelloIcon,
    UelloPagination,
  },
  data() {
    return {
      active: false,
      isActive: false,
      searchText: '',
      filter: [],
      allSelected: false,
      indeterminate: false,
      statusFilter: [],
      currentPage: 1,
      rows: 0,
      perPage: 500,
      created_at: null,
      date: null,
    };
  },
  created() {
    this.filter = this.getSelectedStatus;
  },
  mounted() {
    this.changePagination();
  },
  watch: {
    active(val) {
      setTimeout(() => {
        this.isActive = val;
      }, 300);
    },
    filter(val) {
      if (val.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (val.length === this.statuses.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }

      this.setSelectedStatus(val);

      let mappedFilters = [];
      val.forEach(value => {
        const newValue = value.split(',');
        mappedFilters = [...mappedFilters, ...newValue];
      });

      this.$emit('changeFilter', { status: mappedFilters, created_at: this.date });
    },
    pagination(val, oldVal) {
      if (val && val !== oldVal) {
        this.changePagination();
      }
    },
    statuses(val) {
      if (this.statusFilter.length === 0) {
        this.statusFilter = val;
      }
    },
  },
  computed: {
    ...mapGetters({
      getSelectedStatus: types.GETTER_MAP_STATUS,
      getSelectedDate: types.GETTER_MAP_SELECTED_DATE,
    }),
    selectAll() {
      return this.statuses.map(v => v.value);
    },
    defaultStatus() {
      return this.statuses
        .filter(v => v.value !== 'entregue' && v.value !== 'coletado')
        .map(v => v.value);
    },
  },
  methods: {
    ...mapMutations({
      setSelectedStatus: types.MUTATE_MAP_STATUS,
      setSelectedDate: types.MUTATE_MAP_SELECTED_DATE,
    }),
    formatDate(date) {
      if (date) {
        const { start, end } = date;

        const from = format(start, 'yyyy-MM-dd');
        const to = format(end, 'yyyy-MM-dd');
        this.date = { from, to };
        this.setSelectedDate({ from, to });
      }

      this.filter = [...this.filters];
    },
    changeAll(checked) {
      this.filter = checked ? this.selectAll : [];
    },
    handlePagination(page) {
      this.$emit('setPage', page || 1);
    },
    changePagination() {
      const { total, per_page, current_page } = this.pagination;

      this.rows = total || 1;
      this.currentPage = current_page || 1;
      this.perPage = per_page || 100;
    },
  },
};
</script>

<style lang="scss">
// slide-right
.slide-right-filter-enter-active,
.slide-right-filter-leave-active {
  transition: all 300ms ease;
}
.slide-right-filter-enter,
.slide-right-filter-leave-to {
  transform: translateX(300px);
}

.map-float-filter {
  position: absolute;
  top: 40vh;
  right: 0;
  z-index: 12;

  &.active {
    top: calc(50vh - 175px);
  }

  button {
    border: 0;
    cursor: pointer;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    outline: 0;
  }

  .filter-container {
    width: 300px;
    min-height: 200px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    border: 1px solid var(--primary);
    border-right: 0;

    .custom-switch {
      input,
      label {
        cursor: pointer;
      }
    }

    .custom-control-label {
      span {
        color: var(--dark);
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: var(--primary);
      background-color: var(--primary);
    }

    .close-icon {
      position: absolute;
      top: 2px;
      right: 2px;
    }

    .search-input {
      padding-left: 2.2rem;
    }

    .search-icon-input {
      position: absolute;
      top: 2px;
      left: 12px;
    }
  }

  .scroll-container {
    position: relative;
    height: 120px;
    overflow: hidden;
    max-height: 120px;

    > .ps__rail-y,
    > .ps__thumb-y {
      opacity: 0.6;
    }
  }
}
</style>
